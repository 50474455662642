import { ContentOnexOneColumn } from "./styles";
import ContentImage from "@/shared/ContentImage";
import ContentTitle from "@/shared/ContentTitle";
import { NormalArticle } from "@/widgets/Nota/Templates/stylesModulesGenericNormal.js";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import VideoLoop from "@/shared/VideoLoop";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import useNewsDataModules from "@/hooks/useNewsDataModules";
const OnexOneColumn = (props) => {
  const {
    asPath,
    author = [],
    authorSignature,
    containerName,
    date,
    iconMultimedia,
    image,
    imagePreview,
    imageWeb,
    isPreview,
    payWallExclusive,
    section = [],
    subtitle,
    title,
    url,
    urlTarget,
    videosOpenLoop,
    videoLoop
  } = useNewsDataModules(props, {
    desktop: ["rectangle_sm"],
    mobile: ["rectangle_lg","rectangle_sm"],
  });

  return (
    <>
      <NormalArticle>
        <ContentOnexOneColumn className={`onexoneColumn ${imageWeb.desktop.image == "#" && "noImage"}`}  >
          <ContentTitle
            type="onexoneColumn"
            section={section}
            author={author}
            authorSignature={authorSignature}
            title={title}
            subtitle={subtitle}
            payWallExclusive={payWallExclusive}
            date={date}
          />
          {videosOpenLoop?.enabled ?
            <VideoLoop videoLoop={videoLoop} />
            : imageWeb.desktop.image != "#" &&
            <picture>
              {isPreview ? (
                imagePreview && <ContentImage iconMultimedia={iconMultimedia} data={imagePreview} alt={title} />
              ) : (
                <ContentImage iconMultimedia={iconMultimedia} data={imageWeb} alt={title} image={image} />
              )}
            </picture>
          }
          <LinkAbsolute href={url} target={urlTarget} aria-label={title} onClick={() => clickContent({ contentType: title, contentList: containerName }, url,asPath)} />
        </ContentOnexOneColumn>
      </NormalArticle>
    </>
  );
};

export default OnexOneColumn;
